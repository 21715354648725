<template>
    <div class="search">
        <el-form>
            <el-row :gutter="25">
                <el-col :span="6">
                    <el-form-item label="查询时间：">
                        <el-date-picker v-model="searchDate" type="daterange" unlink-panels range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" :shortcuts="shortcuts" />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-button type="primary" @click="search">
                        <el-icon>
                            <Search />
                        </el-icon>
                        查询
                    </el-button>
                </el-col>
            </el-row>
        </el-form>
    </div>
    <div class="profit-box">
        <div class="agent box">
            <div class="box-title">代理商分润</div>
            <el-row :gutter="25">
                <el-col :span="6">
                    <div class="box-items">
                        <el-card shadow="hover">
                            <template #header>
                                总交易笔数
                            </template>
                            <div class="data">{{ agentData.transaction_number }}</div>
                        </el-card>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="box-items">
                        <el-card shadow="hover">
                            <template #header>
                                总交易金额
                            </template>
                            <div class="data">{{ agentData.total_amount ? agentData.total_amount : 0 }}</div>
                        </el-card>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="box-items">
                        <el-card shadow="hover">
                            <template #header>
                                总手续费
                            </template>
                            <div class="data">{{ agentData.fee_amount ? agentData.fee_amount : 0 }}</div>
                        </el-card>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="box-items">
                        <el-card shadow="hover">
                            <template #header>
                                总退款金额
                            </template>
                            <div class="data">{{ agentData.refund_amount ? agentData.refund_amount : 0 }}</div>
                        </el-card>
                    </div>
                </el-col>
            </el-row>
            <el-row :gutter="25" style="margin-top:15px">
                <el-col :span="6">
                    <div class="box-items">
                        <el-card shadow="hover">
                            <template #header>
                                总最后收益
                            </template>
                            <div class="data">{{ agentData.last_profit ? agentData.last_profit : 0 }}</div>
                        </el-card>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="box-items">
                        <el-card shadow="hover">
                            <template #header>
                                总代理商收益
                            </template>
                            <div class="data">{{ agentData.agent_profit ? agentData.agent_profit : 0 }}</div>
                        </el-card>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="box-items">
                        <el-card shadow="hover">
                            <template #header>
                                总应收下级收益
                            </template>
                            <div class="data">{{ agentData.receivable_profit ? agentData.receivable_profit : 0 }}</div>
                        </el-card>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="box-items">
                        <el-card shadow="hover">
                            <template #header>
                                总上级应收收益
                            </template>
                            <div class="data">{{ agentData.superior_receivable_profit ?
                                    agentData.superior_receivable_profit : 0
                            }}</div>
                        </el-card>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="salesman box">
            <div class="box-title">业务员分润</div>

            <el-row :gutter="25">
                <el-col :span="6">
                    <div class="box-items">
                        <el-card shadow="hover">
                            <template #header>
                                总交易笔数
                            </template>
                            <div class="data">{{ salesmanData.transaction_number }}</div>
                        </el-card>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="box-items">
                        <el-card shadow="hover">
                            <template #header>
                                总交易金额
                            </template>
                            <div class="data">{{ salesmanData.total_amount ? salesmanData.total_amount : 0 }}</div>
                        </el-card>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="box-items">
                        <el-card shadow="hover">
                            <template #header>
                                总手续费
                            </template>
                            <div class="data">{{ salesmanData.fee_amount ? salesmanData.fee_amount : 0 }}</div>
                        </el-card>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="box-items">
                        <el-card shadow="hover">
                            <template #header>
                                总退款金额
                            </template>
                            <div class="data">{{ salesmanData.refund_amount ? salesmanData.refund_amount : 0 }}</div>
                        </el-card>
                    </div>
                </el-col>
            </el-row>
            <el-row :gutter="25" style="margin-top:15px">
                <el-col :span="6">
                    <div class="box-items">
                        <el-card shadow="hover">
                            <template #header>
                                总最后收益
                            </template>
                            <div class="data">{{ salesmanData.last_profit ? salesmanData.last_profit : 0 }}</div>
                        </el-card>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="box-items">
                        <el-card shadow="hover">
                            <template #header>
                                总收益
                            </template>
                            <div class="data">{{ salesmanData.agent_profit ? salesmanData.agent_profit : 0 }}</div>
                        </el-card>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="box-items">
                        <el-card shadow="hover">
                            <template #header>
                                总应收下级收益
                            </template>
                            <div class="data">{{ salesmanData.receivable_profit ? salesmanData.receivable_profit : 0 }}
                            </div>
                        </el-card>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="box-items">
                        <el-card shadow="hover">
                            <template #header>
                                总上级应收收益
                            </template>
                            <div class="data">{{ salesmanData.superior_receivable_profit ?
                                    salesmanData.superior_receivable_profit : 0
                            }}</div>
                        </el-card>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted } from 'vue';
import { Search } from '@element-plus/icons-vue'
import { timeFormat } from '@/func/time'
const { proxy } = getCurrentInstance()

const agentData = ref({})
const salesmanData = ref({})

const searchDate = ref([])
const shortcuts = [
    {
        text: '过去一星期',
        value: () => {
            const end = new Date()
            const h = end.getHours()
            const m = end.getMinutes()
            const i = end.getSeconds()
            end.setTime(end.getTime() - (i + m * 60 + h * 3600 + 1) * 1000)
            console.log(timeFormat(end, 'datetime'))
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)

            return [start, end]
        },
    },
    {
        text: '过去一个月',
        value: () => {
            const end = new Date()
            const h = end.getHours()
            const m = end.getMinutes()
            const i = end.getSeconds()
            end.setTime(end.getTime() - (i + m * 60 + h * 3600 + 1) * 1000)

            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            return [start, end]
        },
    },
    {
        text: '过去三个月',
        value: () => {
            const end = new Date()
            const h = end.getHours()
            const m = end.getMinutes()
            const i = end.getSeconds()
            end.setTime(end.getTime() - (i + m * 60 + h * 3600 + 1) * 1000)

            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            return [start, end]
        },
    },
]

onMounted(() => {
    proxy.$api.settlement.agent().then(r => {
        if (r.status == 200) {
            agentData.value = r.data
        }
    })

    proxy.$api.settlement.salesman().then(r => {
        if (r.status == 200) {
            salesmanData.value = r.data
        }
    })
})

const search = () => {
    const start = searchDate.value[0]
    const end = searchDate.value[1]
    console.log(timeFormat(end, 'datetime'))
    end.setTime(end.getTime() + 3600 * 1000 * 24 - 1)

    const timeStart = timeFormat(start, 'datetime')
    const timeEnd = timeFormat(end, 'datetime')

    const params = { time_start: timeStart, time_end: timeEnd }

    proxy.$api.settlement.agent(params).then(r => {
        if (r.status == 200) {
            agentData.value = r.data
        }
    })

    proxy.$api.settlement.salesman(params).then(r => {
        if (r.status == 200) {
            salesmanData.value = r.data
        }
    })
}
</script>

<style lang="less" scoped>
.profit-box {
    .box {
        .box-title {
            height: 50px;
            line-height: 50px;
            margin-bottom: 15px;
            font-size: 18px;
        }

        .box-items {
            .data {
                text-align: center;
                font-size: 20px;
                color: rgb(51, 51, 51)
            }
        }
    }

    .salesman {
        margin-top: 25px;
    }
}
</style>